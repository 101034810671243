import * as React from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import Splashscreen from '../../components/splashscreen/splashscreen'
import './layout.css'
/**
 * Layout Component. Create a layout for the html structure
 * @param {Object} props - Object with these properties:
 *  @param {Object} location - Object with the location information
 *  @param {String} children - Elements to include in the layout
 * @returns Layout as JSX Component
 */
const Layout = ({ location, children, onAnimationComplete }) => {
  const isRootPath = location.pathname === '/'
  const showSplashScreen = !location.state?.prevPath

  return (
    <div className='global-wrapper' data-is-root-path={isRootPath}>
      {isRootPath && <Splashscreen onAnimationComplete={onAnimationComplete} show={showSplashScreen} />}
      <Header />
      <main className='gl-wrapper'>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
