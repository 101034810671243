import * as React from 'react'
import { Link } from 'gatsby'
import { isBrowser } from '../../utils/utils'
import './header.css'

/**
 * Header Component. Create a header for the website
 * @returns Header as JSX Component
 */
const Header = () => {
  const prevPath = isBrowser() ? window.location.pathname : ''
  return (
    <header className='gl-header'>
      <div className='gl-header__container'>
        <Link to='/' state={{ prevPath: prevPath }}>
          <img src='/gersonlazaro-logo.svg' alt='Gerson Lázaro' />
        </Link>
      </div>

    </header>
  )
}

export default Header
