import React, { useEffect, createRef, useState } from 'react'
import lottie from 'lottie-web'
import animation from '../../animations/initial-animation.json'
import './splashscreen.css'

const Splashscreen = ({ onAnimationComplete, show }) => {
  const [visibilityState, setVisibilityState] = useState('open')
  const animationContainer = createRef()

  const onComplete = () => {
    setVisibilityState('fade-out')
    setTimeout(() => {
      setVisibilityState('closed')
      onAnimationComplete(true)
    }, 200)
  }

  useEffect(() => {
    if (show) {
      const anim = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animation
      })
      anim.onComplete = onComplete
      return () => anim.destroy()
    } else {
      onComplete()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {show &&
        <div className={`gl-splashscreen gl-splashscreen--${visibilityState}`}>
          <div className='gl-splashscreen__animation' ref={animationContainer} />
        </div>}
    </>
  )
}

export default Splashscreen
