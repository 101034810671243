import { Link } from 'gatsby'
import * as React from 'react'
import { isBrowser } from '../../utils/utils'
import './footer.css'

/**
 * Footer Component. Create a footer for the website
 * @returns Footer as JSX Component
 */
const Footer = () => {
  const prevPath = isBrowser() ? window.location.pathname : ''
  return (
    <footer className='gl-footer'>
      <div className='gl-footer__container'>
        <a className='gl-footer__link' href='mailto:iam@gersonlazaro.com' target='_blank' rel='noreferrer'>ESCRÍBEME</a>
        <Link className='gl-footer__link gl-footer__link--separator' to='/gerson-lazaro' state={{ prevPath: prevPath }}>¿QUIÉN SOY?</Link>
        <a href='https://gersonlazaroc.medium.com/' target='_blank' rel='noreferrer' className='gl-footer__link-icon gl-footer__link-icon--medium'> </a>
        <a href='https://github.com/GersonLazaro' target='_blank' rel='noreferrer' className='gl-footer__link-icon gl-footer__link-icon--github'> </a>
        <a href='https://co.linkedin.com/in/gersonlazaro' target='_blank' rel='noreferrer' className='gl-footer__link-icon gl-footer__link-icon--linkedin'> </a>
      </div>
    </footer>
  )
}

export default Footer
